import clsx from 'clsx';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Coins } from 'src/components/dnd/dnd-types';

function CoinBox(props: { name: string; value: number | undefined }): JSX.Element {
  return (
    <div className="flex items-center">
      <div
        data-tip
        data-for={props.name}
        className={clsx(
          'bg-yellow-300',
          'rounded-full',
          'border-2',
          'border-yellow-400',
          'h-6',
          'w-6',
          'text-center',
          'text-sm',
          'font-bold',
          'align-middle',
          'text-yellow-800'
        )}
      >
        {props.name.charAt(0)}
      </div>
      <ReactTooltip effect="solid" id={props.name}>
        {props.name}
      </ReactTooltip>
      <div
        className={clsx(
          'ml-1',
          'bg-gray-300',
          'h-8',
          'font-medium',
          'rounded',
          'flex',
          'items-center',
          'px-2',
          'py-1',
          'text-gray-800',
          'flex-grow'
        )}
      >
        {props.value?.toLocaleString()}
      </div>
    </div>
  );
}

interface Props {
  coins?: Coins;
}

export default function CoinDisplay(props: Props): JSX.Element {
  return (
    <div className="grid sm:grid-cols-5 mt-4 gap-4">
      <CoinBox name="Copper" value={props.coins?.copper} />
      <CoinBox name="Silver" value={props.coins?.silver} />
      <CoinBox name="Electum" value={props.coins?.electrum} />
      <CoinBox name="Gold" value={props.coins?.gold} />
      <CoinBox name="Platinum" value={props.coins?.platinum} />
    </div>
  );
}
