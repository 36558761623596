import { WeightedTableEntry } from 'src/helpers/weighted-table';
import { DieExp } from 'src/helpers/dice';
import { Coins } from 'src/components/dnd/dnd-types';
import { coinGenerator, multiplyRoll } from 'src/helpers/treasure-generators';

export default class IndividualTreasure {
  static CR4: WeightedTableEntry<Coins>[] = [
    {
      weight: 30,
      value: coinGenerator({
        copper: DieExp.fromString('5d6').roll,
      }),
    },
    {
      weight: 30,
      value: coinGenerator({
        silver: DieExp.fromString('4d6').roll,
      }),
    },
    {
      weight: 10,
      value: coinGenerator({
        electrum: DieExp.fromString('3d6').roll,
      }),
    },
    {
      weight: 25,
      value: coinGenerator({
        gold: DieExp.fromString('3d6').roll,
      }),
    },
    {
      weight: 5,
      value: coinGenerator({
        platinum: DieExp.fromString('1d6').roll,
      }),
    },
  ];

  static CR10: WeightedTableEntry<Coins>[] = [
    {
      weight: 30,
      value: coinGenerator({
        copper: multiplyRoll(DieExp.fromString('4d6'), 100),
        electrum: multiplyRoll(DieExp.fromString('1d6'), 10),
      }),
    },
    {
      weight: 30,
      value: coinGenerator({
        silver: multiplyRoll(DieExp.fromString('6d6'), 10),
        gold: multiplyRoll(DieExp.fromString('2d6'), 10),
      }),
    },
    {
      weight: 10,
      value: coinGenerator({
        electrum: multiplyRoll(DieExp.fromString('3d6'), 10),
        gold: multiplyRoll(DieExp.fromString('2d6'), 10),
      }),
    },
    {
      weight: 25,
      value: coinGenerator({
        gold: multiplyRoll(DieExp.fromString('4d6'), 10),
      }),
    },
    {
      weight: 5,
      value: coinGenerator({
        gold: multiplyRoll(DieExp.fromString('2d6'), 10),
        platinum: DieExp.fromString('3d6').roll,
      }),
    },
  ];

  static CR16: WeightedTableEntry<Coins>[] = [
    {
      weight: 20,
      value: coinGenerator({
        silver: multiplyRoll(DieExp.fromString('4d6'), 100),
        gold: multiplyRoll(DieExp.fromString('1d6'), 100),
      }),
    },
    {
      weight: 15,
      value: coinGenerator({
        electrum: multiplyRoll(DieExp.fromString('1d6'), 100),
        gold: multiplyRoll(DieExp.fromString('1d6'), 100),
      }),
    },
    {
      weight: 40,
      value: coinGenerator({
        gold: multiplyRoll(DieExp.fromString('2d6'), 100),
        platinum: multiplyRoll(DieExp.fromString('1d6'), 10),
      }),
    },
    {
      weight: 25,
      value: coinGenerator({
        gold: multiplyRoll(DieExp.fromString('2d6'), 100),
        platinum: multiplyRoll(DieExp.fromString('2d6'), 10),
      }),
    },
  ];

  static CR17: WeightedTableEntry<Coins>[] = [
    {
      weight: 15,
      value: coinGenerator({
        electrum: multiplyRoll(DieExp.fromString('2d6'), 1000),
        gold: multiplyRoll(DieExp.fromString('8d6'), 100),
      }),
    },
    {
      weight: 40,
      value: coinGenerator({
        gold: multiplyRoll(DieExp.fromString('1d6'), 1000),
        platinum: multiplyRoll(DieExp.fromString('1d6'), 100),
      }),
    },
    {
      weight: 45,
      value: coinGenerator({
        gold: multiplyRoll(DieExp.fromString('1d6'), 1000),
        platinum: multiplyRoll(DieExp.fromString('2d6'), 100),
      }),
    },
  ];
}
