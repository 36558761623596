import React from 'react';
import { MagicItemEntry, Rarity } from 'src/resources/magic-item-tables';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faSword, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

interface Props {
  magicItem: MagicItemEntry;
}

function classesFromRarity(rarity: Rarity | undefined): [string, string] {
  switch (rarity) {
    case Rarity.UNCOMMON:
      return ['bg-green-100 border-green-200 text-green-800 hover:bg-green-200', 'text-green-600'];
    case Rarity.RARE:
      return ['bg-blue-100 border-blue-200 text-blue-800 hover:bg-blue-200', 'text-blue-600'];
    case Rarity.VERY_RARE:
      return [
        'bg-purple-100 border-purple-200 text-purple-800 hover:bg-purple-200',
        'text-purple-600',
      ];
    case Rarity.LEGENDARY:
      return [
        'bg-orange-100 border-orange-200 text-orange-800 hover:bg-orange-200',
        'text-orange-600',
      ];
    case Rarity.ARTIFACT:
      return ['bg-red-100 border-red-200 text-red-800 hover:bg-red-200', 'text-red-600'];
    case Rarity.COMMON:
    default:
      return ['bg-gray-100 border-gray-300 text-gray-700 hover:bg-gray-300', 'text-gray-600'];
  }
}

export default function MagicItemField(props: Props): JSX.Element {
  const classes = classesFromRarity(props.magicItem?.rarity);

  return (
    <a
      href={props.magicItem.link}
      target="blank"
      rel="noopener"
      className={clsx(
        'mt-4 h-12 font-medium border-2 rounded flex items-center px-4 py-1 flex-grow flex',
        classes[0]
      )}
    >
      <span data-tip="magic-item" className={clsx('fa-layers fa-fw mr-4', classes[1])}>
        <FontAwesomeIcon icon={faSword} />
        <FontAwesomeIcon icon={faSparkles} transform="shrink-4 up-6 left-2" opacity={0.5} />
      </span>
      <ReactTooltip place="top" type="dark" effect="solid">
        Magic Item
      </ReactTooltip>
      {props.magicItem.name}
      <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-auto" />
    </a>
  );
}
