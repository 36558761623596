import React, { useState } from 'react';
import clsx from 'clsx';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onClick: () => void;
  selected: boolean;
  id: number;
  updateSelected: (selected: number) => void;
}

function GroupButton(props: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <button
      className={clsx(
        'flex-grow',
        'overflow-hidden',
        'bg-purple-300',
        'hover:bg-purple-400',
        'text-sm',
        'text-purple-900',
        'hover:text-gray-900',
        'font-medium',
        'focus:outline-none',
        'first:rounded-t',
        'sm:first:rounded-none',
        'sm:first:rounded-l',
        'last:rounded-b',
        'sm:last:rounded-none',
        'sm:last:rounded-r',
        'border-b-2',
        'sm:border-b-0',
        'sm:border-r-2',
        'last:border-b-0',
        'sm:last:border-r-0',
        'border-white'
      )}
      onClick={() => {
        props.updateSelected(props.id);
        props.onClick();
      }}
    >
      <div className={clsx('pt-1 px-3')}>{props.children}</div>
      <div className={clsx('w-full', 'h-1', props.selected && 'bg-purple-400')} />
    </button>
  );
}

interface ButtonGroupProps {
  buttons: {
    name: string | IconDefinition;
    onClick: () => void;
  }[];
}

export default function ButtonGroup(props: ButtonGroupProps): JSX.Element {
  const [selected, setSelected] = useState<number>();

  return (
    <div className="flex flex-col sm:flex-row">
      {props.buttons.map((btn, index) => (
        <GroupButton
          key={index}
          id={index}
          onClick={btn.onClick}
          updateSelected={setSelected}
          selected={selected === index}
        >
          {typeof btn.name === 'string' ? btn.name : <FontAwesomeIcon icon={btn.name} />}
        </GroupButton>
      ))}
    </div>
  );
}
