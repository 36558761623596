import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem, faPaintBrush } from '@fortawesome/pro-solid-svg-icons';
import { HoardItem } from 'src/helpers/treasure-generators';

export enum HoardItemType {
  GEMSTONE,
  ART_OBJECT,
}

interface Props {
  hoardItem: HoardItem;
  type: HoardItemType;
}

export default function HoardItemField(props: Props): JSX.Element {
  return (
    <div className="mt-4 py-3 px-4 rounded bg-gray-300 text-gray-800 flex">
      <FontAwesomeIcon
        icon={props.type === HoardItemType.GEMSTONE ? faGem : faPaintBrush}
        className="mr-4 text-blue-400 my-auto"
      />
      <div className="flex flex-grow flex-wrap items-baseline">
        <div className="flex">
          <span className="font-medium mr-2">{props.hoardItem.count + 'x'}</span>
          <span>{props.hoardItem.name}</span>
        </div>

        <div className="ml-auto pl-4 font-medium text-sm text-right">{props.hoardItem.value} GP</div>
      </div>
    </div>

    // <div className="mt-4 min-h-12 rounded items-center px-4 py-1 flex-grow flex flex-wrap bg-gray-300 text-gray-800">
    //   <div>
    //     <FontAwesomeIcon
    //       icon={props.type === HoardItemType.GEMSTONE ? faGem : faPaintBrush}
    //       className="mr-4 text-blue-400"
    //     />

    //   </div>
    // </div>
  );
}
