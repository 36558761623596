import { armorOfResistanceBuilder, ringOfResistanceBuilder } from 'src/helpers/magic-item-helpers';
import WeightedTable from 'src/helpers/weighted-table';

export enum Rarity {
  COMMON,
  UNCOMMON,
  RARE,
  VERY_RARE,
  LEGENDARY,
  ARTIFACT,
}

export interface MagicItemEntry {
  name: string;
  link: string;
  rarity: Rarity;
}

export default class MagicItemTables {
  static A: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 50,
      value: {
        name: 'Potion of Healing',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-healing',
        rarity: Rarity.COMMON,
      },
    },
    {
      weight: 10,
      value: {
        name: 'Spell Scroll (cantrip)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.COMMON,
      },
    },
    {
      weight: 10,
      value: {
        name: 'Potion of Climbing',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-climbing',
        rarity: Rarity.COMMON,
      },
    },
    {
      weight: 20,
      value: {
        name: 'Spell Scroll (1st level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.COMMON,
      },
    },
    {
      weight: 4,
      value: {
        name: 'Spell Scroll (2nd level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 4,
      value: {
        name: 'Potion of Healing (greater)',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-healing-greater',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Bag of Holding',
        link: 'https://www.dndbeyond.com/magic-items/bag-of-holding',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Driftglobe',
        link: 'https://www.dndbeyond.com/magic-items/driftglobe',
        rarity: Rarity.UNCOMMON,
      },
    },
  ]);

  static B: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 15,
      value: {
        name: 'Potion of Healing (greater)',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-healing-greater',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 7,
      value: {
        name: 'Potion of Fire Breath',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-fire-breath',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 7,
      value: {
        name: 'Potion of Resistance',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-resistance',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Ammunition, +1',
        link: 'https://www.dndbeyond.com/magic-items/ammunition-1',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Animal Friendship',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-animal-friendship',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of hill Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-hill-giant-strength',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Growth',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-growth',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Water Breathing',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-water-breathing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Spell Scroll (2nd level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Spell Scroll (3rd level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Bag of Holding',
        link: 'https://www.dndbeyond.com/magic-items/bag-of-holding',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 3,
      value: {
        name: "Keoghtom's Ointment",
        link: 'https://www.dndbeyond.com/magic-items/keoghtoms-ointment',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Oil of Slipperiness',
        link: 'https://www.dndbeyond.com/magic-items/oil-of-slipperiness',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Dust of Disappearance',
        link: 'https://www.dndbeyond.com/magic-items/dust-of-disappearance',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Dust of Dryness',
        link: 'https://www.dndbeyond.com/magic-items/dust-of-dryness',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Dust of Sneezing and Choking',
        link: 'https://www.dndbeyond.com/magic-items/dust-of-sneezing-and-choking',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Elemental Gem',
        link: 'https://www.dndbeyond.com/magic-items/elemental-gem',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Philter of Love',
        link: 'https://www.dndbeyond.com/magic-items/philter-of-love',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Alchemy Jug',
        link: 'https://www.dndbeyond.com/magic-items/alchemy-jug',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Cap of Water Breathing',
        link: 'https://www.dndbeyond.com/magic-items/cap-of-water-breathing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Cloak of the Manta Ray',
        link: 'https://www.dndbeyond.com/magic-items/cloak-of-the-manta-ray',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Driftglobe',
        link: 'https://www.dndbeyond.com/magic-items/driftglobe',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Goggles of Night',
        link: 'https://www.dndbeyond.com/magic-items/goggles-of-night',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Helm of Comprehending Languages',
        link: 'https://www.dndbeyond.com/magic-items/helm-of-comprehending-languages',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Immovable Rod',
        link: 'https://www.dndbeyond.com/magic-items/immovable-rod',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Lantern of Revealing',
        link: 'https://www.dndbeyond.com/magic-items/lantern-of-revealing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: "Mariner's Armor",
        link: 'https://www.dndbeyond.com/magic-items/mariners-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Mithral Armor',
        link: 'https://www.dndbeyond.com/magic-items/mithral-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Potion of Poison',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-poison',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Swimming',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-swimming',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Robe of Useful Items',
        link: 'https://www.dndbeyond.com/magic-items/robe-of-useful-items',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Rope of Climbing',
        link: 'https://www.dndbeyond.com/magic-items/rope-of-climbing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Saddle of the Cavalier',
        link: 'https://www.dndbeyond.com/magic-items/saddle-of-the-cavalier',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Magic Detection',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-magic-detection',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Secrets',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-secrets',
        rarity: Rarity.UNCOMMON,
      },
    },
  ]);

  static C: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 15,
      value: {
        name: 'Potion of Healing (superior)',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-healing-superior',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 7,
      value: {
        name: 'Spell Scroll (4th level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Ammunition, +2',
        link: 'https://www.dndbeyond.com/magic-items/ammunition-2',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Clairvoyance',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-clairvoyance',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Diminution',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-diminution',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Gaseous Form',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-gaseous-form',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Frost Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-frost-giant-strength',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Stone Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-stone-giant-strength',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Heroism',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-heroism',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Invulnerability',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-invulnerability',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Mind Reading',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-mind-reading',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Spell Scroll (5th level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Elixir of Health',
        link: 'https://www.dndbeyond.com/magic-items/elixir-of-health',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Oil of Etherealness',
        link: 'https://www.dndbeyond.com/magic-items/oil-of-etherealness',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Potion of Fire Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-fire-giant-strength',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Quaals Feather Token',
        link: 'https://www.dndbeyond.com/magic-items/quaals-feather-token',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Scroll of Protection',
        link: 'https://www.dndbeyond.com/magic-items/scroll-of-protection',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Bag of Beans',
        link: 'https://www.dndbeyond.com/magic-items/bag-of-beans',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Bead of Force',
        link: 'https://www.dndbeyond.com/magic-items/bead-of-force',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Chime of Opening',
        link: 'https://www.dndbeyond.com/magic-items/chime-of-opening',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Decanter of Endless Water',
        link: 'https://www.dndbeyond.com/magic-items/decanter-of-endless-water',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Eyes of Minute Seeing',
        link: 'https://www.dndbeyond.com/magic-items/eyes-of-minute-seeing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Folding Boat',
        link: 'https://www.dndbeyond.com/magic-items/folding-boat',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: "Heward's Handy Haversack",
        link: 'https://www.dndbeyond.com/magic-items/hewards-handy-haversack',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Horeshoes of Speed',
        link: 'https://www.dndbeyond.com/magic-items/horseshoes-of-speed',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Necklace of Fireballs',
        link: 'https://www.dndbeyond.com/magic-items/necklace-of-fireballs',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Periapt of Health',
        link: 'https://www.dndbeyond.com/magic-items/periapt-of-health',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Sending Stones',
        link: 'https://www.dndbeyond.com/magic-items/sending-stones',
        rarity: Rarity.UNCOMMON,
      },
    },
  ]);

  static D: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 20,
      value: {
        name: 'Potion of Healing (supreme)',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-healing-supreme',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 10,
      value: {
        name: 'Potion of Invisibility',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-invisibility',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 10,
      value: {
        name: 'Potion of Speed',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-speed',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 10,
      value: {
        name: 'Spell Scroll (6th level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 7,
      value: {
        name: 'Spell Scroll (7th level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Ammunition, +3',
        link: 'https://www.dndbeyond.com/magic-items/ammunition-3',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Oil of Sharpness',
        link: 'https://www.dndbeyond.com/magic-items/oil-of-sharpness',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Flying',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-flying',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Cloud Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-cloud-giant-strength',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Longevity',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-longevity',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Potion of Vitality',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-vitality',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Spell Scroll (8th level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Horseshoes of a Zephyr',
        link: 'https://www.dndbeyond.com/magic-items/horseshoes-of-a-zephyr',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 3,
      value: {
        name: "Nolzur's Marvelous Pigments",
        link: 'https://www.dndbeyond.com/magic-items/nolzurs-marvelous-pigments',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Bag of Devouring',
        link: 'https://www.dndbeyond.com/magic-items/bag-of-devouring',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Portable Hole',
        link: 'https://www.dndbeyond.com/magic-items/portable-hole',
        rarity: Rarity.RARE,
      },
    },
  ]);

  static E: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 30,
      value: {
        name: 'Spell Scroll (8th level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 25,
      value: {
        name: 'Potion of Storm Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-storm-giant-strength',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 15,
      value: {
        name: 'Potion of Healing (supreme)',
        link: 'https://www.dndbeyond.com/magic-items/potion-of-healing-supreme',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 15,
      value: {
        name: 'Spell Scroll (9th level)',
        link: 'https://www.dndbeyond.com/magic-items/spell-scroll',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 8,
      value: {
        name: 'Universal Solvent',
        link: 'https://www.dndbeyond.com/magic-items/universal-solvent',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Arrow of Slaying',
        link: 'https://www.dndbeyond.com/magic-items/arrow-of-slaying',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Sovereign Glue',
        link: 'https://www.dndbeyond.com/magic-items/sovereign-glue',
        rarity: Rarity.LEGENDARY,
      },
    },
  ]);

  static F: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 15,
      value: {
        name: 'Weapon, +1',
        link: 'https://www.dndbeyond.com/magic-items/weapon-1',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Shield, +1',
        link: 'https://www.dndbeyond.com/magic-items/shield-1',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Sentinel Shield',
        link: 'https://www.dndbeyond.com/magic-items/sentinel-shield',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Amulet of Proof Against Detection and Location',
        link:
          'https://www.dndbeyond.com/magic-items/amulet-of-proof-against-detection-and-location',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Boots of Elevenkind',
        link: 'https://www.dndbeyond.com/magic-items/boots-of-elvenkind',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Boots of Striding and Springing',
        link: 'https://www.dndbeyond.com/magic-items/boots-of-striding-and-springing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Bracers of Archery',
        link: 'https://www.dndbeyond.com/magic-items/bracers-of-archery',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Brooch of Shielding',
        link: 'https://www.dndbeyond.com/magic-items/brooch-of-shielding',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Broom of Flying',
        link: 'https://www.dndbeyond.com/magic-items/broom-of-flying',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Cloak of Elvenkind',
        link: 'https://www.dndbeyond.com/magic-items/cloak-of-elvenkind',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Cloak of Protection',
        link: 'https://www.dndbeyond.com/magic-items/cloak-of-protection',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Gauntlets of Ogre Power',
        link: 'https://www.dndbeyond.com/magic-items/gauntlets-of-ogre-power',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Hat of Disguise',
        link: 'https://www.dndbeyond.com/magic-items/hat-of-disguise',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Javelin of Lightning',
        link: 'https://www.dndbeyond.com/magic-items/javelin-of-lightning',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Pearl of Power',
        link: 'https://www.dndbeyond.com/magic-items/pearl-of-power',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Rod of the Pact Keeper, +1',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-the-pact-keeper-1',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Slipprs of Spider Climbing',
        link: 'https://www.dndbeyond.com/magic-items/slippers-of-spider-climbing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Staff of the Adder',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-the-adder',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Staff of the Python',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-the-python',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Sword of Vengeance',
        link: 'https://www.dndbeyond.com/magic-items/sword-of-vengeance',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Trident of Fish Command',
        link: 'https://www.dndbeyond.com/magic-items/trident-of-fish-command',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Wand of Magic Missiles',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-magic-missiles',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Wand of the War Mage, +1',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-the-war-mage-1',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Wand of Web',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-web',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Weapon of Warning',
        link: 'https://www.dndbeyond.com/magic-items/weapon-of-warning',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Adamantine Armor (chain mail)',
        link: 'https://www.dndbeyond.com/magic-items/adamantine-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Adamantine Armor (chain shirt)',
        link: 'https://www.dndbeyond.com/magic-items/adamantine-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Adamantine Armor (scale mail)',
        link: 'https://www.dndbeyond.com/magic-items/adamantine-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Bag of Tricks (gray)',
        link: 'https://www.dndbeyond.com/magic-items/bag-of-tricks',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Bag of Tricks (rust)',
        link: 'https://www.dndbeyond.com/magic-items/bag-of-tricks',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Bag of Tricks (tan)',
        link: 'https://www.dndbeyond.com/magic-items/bag-of-tricks',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Boots of the Winterlands',
        link: 'https://www.dndbeyond.com/magic-items/boots-of-the-winterlands',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Circlet of Blasting',
        link: 'https://www.dndbeyond.com/magic-items/circlet-of-blasting',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Deck of Illusion',
        link: 'https://www.dndbeyond.com/magic-items/deck-of-illusions',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Eversmoking Bottle',
        link: 'https://www.dndbeyond.com/magic-items/eversmoking-bottle',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Eyes of Charming',
        link: 'https://www.dndbeyond.com/magic-items/eyes-of-charming',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Eyes of the Eagle',
        link: 'https://www.dndbeyond.com/magic-items/eyes-of-the-eagle',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Figurine of wondrous power (silver raven)',
        link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-silver-raven',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Gem of Brightness',
        link: 'https://www.dndbeyond.com/magic-items/gem-of-brightness',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Gloves of Missile Snaring',
        link: 'https://www.dndbeyond.com/magic-items/gloves-of-missile-snaring',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Gloves os swimming and Climbing',
        link: 'https://www.dndbeyond.com/magic-items/gloves-of-swimming-and-climbing',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Gloves of Thievery',
        link: 'https://www.dndbeyond.com/magic-items/gloves-of-thievery',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Headband of Intellect',
        link: 'https://www.dndbeyond.com/magic-items/headband-of-intellect',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Helm of Telepathy',
        link: 'https://www.dndbeyond.com/magic-items/helm-of-telepathy',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Instrument of the Bards (Doss lute)',
        link: 'https://www.dndbeyond.com/magic-items/instrument-of-the-bards',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Instrument of the Bards (Fochlucan bandore)',
        link: 'https://www.dndbeyond.com/magic-items/instrument-of-the-bards',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Instrument of the Bards (Mac-Fuimidh cittern)',
        link: 'https://www.dndbeyond.com/magic-items/instrument-of-the-bards',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Medallion of Thoughts',
        link: 'https://www.dndbeyond.com/magic-items/medallion-of-thoughts',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Necklace of Adaptation',
        link: 'https://www.dndbeyond.com/magic-items/necklace-of-adaptation',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Periapt of Round Closure',
        link: 'https://www.dndbeyond.com/magic-items/periapt-of-wound-closure',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Pipes of Haunting',
        link: 'https://www.dndbeyond.com/magic-items/pipes-of-haunting',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Pipes of the Sewers',
        link: 'https://www.dndbeyond.com/magic-items/pipes-of-the-sewers',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Jumping',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-jumping',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Mind Shielding',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-mind-shielding',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Warmth',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-warmth',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Water Walking',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-water-walking',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Quivver of Ehlonna',
        link: 'https://www.dndbeyond.com/magic-items/quiver-of-ehlonna',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Stone of good luck (luckstone)',
        link: 'https://www.dndbeyond.com/magic-items/stone-of-good-luck-luckstone',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wind Fan',
        link: 'https://www.dndbeyond.com/magic-items/wind-fan',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Winged Boots',
        link: 'https://www.dndbeyond.com/magic-items/winged-boots',
        rarity: Rarity.UNCOMMON,
      },
    },
  ]);

  static G: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 11,
      value: {
        name: 'Weapon, +2',
        link: 'https://www.dndbeyond.com/magic-items/weapon-2',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 3,
      value: new WeightedTable<MagicItemEntry>([
        {
          weight: 1,
          value: {
            name: 'Figurine of Wondrous Power (Bronze Griffon)',
            link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-bronze-griffon',
            rarity: Rarity.RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Figurine of Wondrous Power (Ebony Fly)',
            link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-ebony-fly',
            rarity: Rarity.RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Figurine of Wondrous Power (Golden Lions)',
            link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-golden-lions',
            rarity: Rarity.RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Figurine of Wondrous Power (Ivory Goats)',
            link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-ivory-goats',
            rarity: Rarity.RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Figurine of Wondrous Power (Marble Elephant)',
            link:
              'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-marble-elephant',
            rarity: Rarity.RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Figurine of Wondrous Power (Onyx Dog)',
            link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-onyx-dog',
            rarity: Rarity.RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Figurine of Wondrous Power (Serpentine Owl)',
            link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-serpentine-owl',
            rarity: Rarity.RARE,
          },
        },
      ]),
    },
    {
      weight: 1,
      value: {
        name: 'Adamantine Armor (breastplate)',
        link: 'https://www.dndbeyond.com/magic-items/adamantine-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Adamantine armor (splint',
        link: 'https://www.dndbeyond.com/magic-items/adamantine-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Amulet of Health',
        link: 'https://www.dndbeyond.com/magic-items/amulet-of-health',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor of Vulnerability',
        link: 'https://www.dndbeyond.com/magic-items/armor-of-vulnerability',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Arrow-Catching Shield',
        link: 'https://www.dndbeyond.com/magic-items/arrow-catching-shield',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Belt of Dwarvenkind',
        link: 'https://www.dndbeyond.com/magic-items/belt-of-dwarvenkind',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Belt of Hill Giant Strengh',
        link: 'https://www.dndbeyond.com/magic-items/belt-of-hill-giant-strength',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Beserker Axe',
        link: 'https://www.dndbeyond.com/magic-items/berserker-axe',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Boots of Levitation',
        link: 'https://www.dndbeyond.com/magic-items/boots-of-levitation',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Boots os Speed',
        link: 'https://www.dndbeyond.com/magic-items/boots-of-speed',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Bowl of Commanding Water Elementatals',
        link: 'https://www.dndbeyond.com/magic-items/bowl-of-commanding-water-elementals',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Bracers of Defense',
        link: 'https://www.dndbeyond.com/magic-items/bracers-of-defense',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Brazier of Commanding Fire Elementals',
        link: 'https://www.dndbeyond.com/magic-items/brazier-of-commanding-fire-elementals',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Cape of the Mountebank',
        link: 'https://www.dndbeyond.com/magic-items/cape-of-the-mountebank',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Censer of Controlling Air Elementals',
        link: 'https://www.dndbeyond.com/magic-items/censer-of-controlling-air-elementals',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor + 1 (chain mail)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('chain mail'),
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +1 (chain shirt)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('chain shirt'),
    },
    {
      weight: 1,
      value: {
        name: 'Cloak of Displacement',
        link: 'https://www.dndbeyond.com/magic-items/cloak-of-displacement',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Cloak of the Bat',
        link: 'https://www.dndbeyond.com/magic-items/cloak-of-the-bat',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Cube of Force',
        link: 'https://www.dndbeyond.com/magic-items/cube-of-force',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: "Daern's Instant Fortress",
        link: 'https://www.dndbeyond.com/magic-items/daerns-instant-fortress',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Dagger of Venum',
        link: 'https://www.dndbeyond.com/magic-items/dagger-of-venom',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Dimensional Shackles',
        link: 'https://www.dndbeyond.com/magic-items/dimensional-shackles',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Dragon Slayer',
        link: 'https://www.dndbeyond.com/magic-items/dragon-slayer',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Elven Chain',
        link: 'https://www.dndbeyond.com/magic-items/elven-chain',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Flame Tongue',
        link: 'https://www.dndbeyond.com/magic-items/flame-tongue',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Gem of Seeing',
        link: 'https://www.dndbeyond.com/magic-items/gem-of-seeing',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Giant Slayer',
        link: 'https://www.dndbeyond.com/magic-items/giant-slayer',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Glamoured Studded Leather',
        link: 'https://www.dndbeyond.com/magic-items/glamoured-studded-leather',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Helm of Teleportation',
        link: 'https://www.dndbeyond.com/magic-items/helm-of-teleportation',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Horn of Blasting',
        link: 'https://www.dndbeyond.com/magic-items/horn-of-blasting',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: new WeightedTable<MagicItemEntry>([
        {
          weight: 1,
          value: {
            name: 'Horn of Valhalla (silver)',
            link: 'https://www.dndbeyond.com/magic-items/horn-of-valhalla-silver',
            rarity: Rarity.RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Horn of Valhalla (brass)',
            link: 'https://www.dndbeyond.com/magic-items/horn-of-valhalla-brass',
            rarity: Rarity.RARE,
          },
        },
      ]),
    },
    {
      weight: 1,
      value: {
        name: 'Instrument of the Bards (Canaith mandolin)',
        link: 'https://www.dndbeyond.com/magic-items/instrument-of-the-bards',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Instrument of the Bards (Cli lyre)',
        link: 'https://www.dndbeyond.com/magic-items/instrument-of-the-bards',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (awareness)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-awareness',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (protection)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-protection',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (reserve)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-reserve',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (sustenance)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-sustenance',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Iron Bands of Bilarro',
        link: 'https://www.dndbeyond.com/magic-items/iron-bands-of-bilarro',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +1 (leather)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('leather'),
    },
    {
      weight: 1,
      value: {
        name: 'Mace of Disruption',
        link: 'https://www.dndbeyond.com/magic-items/mace-of-disruption',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Mace of Smiting',
        link: 'https://www.dndbeyond.com/magic-items/mace-of-smiting',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Mace of Terror',
        link: 'https://www.dndbeyond.com/magic-items/mace-of-terror',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Mantle of Spell Resistance',
        link: 'https://www.dndbeyond.com/magic-items/mantle-of-spell-resistance',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Necklace of Prayer Beads',
        link: 'https://www.dndbeyond.com/magic-items/necklace-of-prayer-beads',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Periapt of Proof Against Poison',
        link: 'https://www.dndbeyond.com/magic-items/periapt-of-proof-against-poison',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Animal Influence',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-animal-influence',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Evasion',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-evasion',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Feather Falling',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-feather-falling',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Free Action',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-free-action',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Protection',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-protection',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: ringOfResistanceBuilder(),
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Spell Storing',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-spell-storing',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of the Ram',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-the-ram',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of X-Ray Vision',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-x-ray-vision',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Robe of Eyes',
        link: 'https://www.dndbeyond.com/magic-items/robe-of-eyes',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Rod of rulership',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-rulership',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Rod of the Pact Keeper, +2',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-the-pact-keeper-2',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Rope of Entanglement',
        link: 'https://www.dndbeyond.com/magic-items/rope-of-entanglement',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor +1 (scale mail)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('scale mail'),
    },
    {
      weight: 1,
      value: {
        name: 'Shield, +2',
        link: 'https://www.dndbeyond.com/magic-items/shield-2',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Shield of Missile Attraction',
        link: 'https://www.dndbeyond.com/magic-items/shield-of-missile-attraction',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Staff of Charming',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-charming',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Staff of Healing',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-healing',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Staff of Swarming Insects',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-swarming-insects',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Staff of the Woodlands',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-the-woodlands',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Staff of Withering',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-withering',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Stone of Controlling Earth Elementals',
        link: 'https://www.dndbeyond.com/magic-items/stone-of-controlling-earth-elementals',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Sun Blade',
        link: 'https://www.dndbeyond.com/magic-items/sun-blade',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Sword of Life Stealing',
        link: 'https://www.dndbeyond.com/magic-items/sword-of-life-stealing',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Sword of Wounding',
        link: 'https://www.dndbeyond.com/magic-items/sword-of-wounding',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Tentacle Rod',
        link: 'https://www.dndbeyond.com/magic-items/tentacle-rod',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Vicious Weapon',
        link: 'https://www.dndbeyond.com/magic-items/vicious-weapon',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Binding',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-binding',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Enemy Detection',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-enemy-detection',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Fear',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-fear',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Fireballs',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-fireballs',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Lightning Bolts',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-lightning-bolts',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Paralysis',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-paralysis',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of the War Mage, +2',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-the-war-mage-2',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wand of Wonder',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-wonder',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Wings of Flying',
        link: 'https://www.dndbeyond.com/magic-items/wings-of-flying',
        rarity: Rarity.RARE,
      },
    },
  ]);

  static H: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 10,
      value: {
        name: 'Weapon, +2',
        link: 'https://www.dndbeyond.com/magic-items/weapon-3',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Amulet of the Planes',
        link: 'https://www.dndbeyond.com/magic-items/amulet-of-the-planes',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Carpet of Flying',
        link: 'https://www.dndbeyond.com/magic-items/carpet-of-flying',
        rarity: Rarity.VERY_RARE,
      },
    },

    {
      weight: 2,
      value: {
        name: 'Crystal Ball (very rare version)',
        link: 'https://www.dndbeyond.com/magic-items/crystal-ball',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Ring of Regeneration',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-regeneration',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Ring of Shooting Stars',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-shooting-stars',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Ring of Telekinesis',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-telekinesis',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Robe of Scintillating Colors',
        link: 'https://www.dndbeyond.com/magic-items/robe-of-scintillating-colors',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Robe of Stars',
        link: 'https://www.dndbeyond.com/magic-items/robe-of-stars',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Rod of Absorption',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-absorption',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Rod of Alertness',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-alertness',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Rod of Security',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-security',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Rod of the Pact Keeper, +3',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-the-pact-keeper-3',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Scimitar of Speed',
        link: 'https://www.dndbeyond.com/magic-items/scimitar-of-speed',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Shield, +3',
        link: 'https://www.dndbeyond.com/magic-items/shield-3',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Staff of Fire',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-fire',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Staff of Frost',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-frost',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Staff of Power',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-power',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Staff of Striking',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-striking',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Staff of Thunder and Lightning',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-thunder-and-lightning',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Sword of Sharpness',
        link: 'https://www.dndbeyond.com/magic-items/sword-of-sharpness',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Wand of Polymorph',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-polymorph',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Wand of the War Mage, +3',
        link: 'https://www.dndbeyond.com/magic-items/wand-of-the-war-mage-3',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Adamantine Armor (half plate)',
        link: 'https://www.dndbeyond.com/magic-items/adamantine-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Adamantine Armor (plate)',
        link: 'https://www.dndbeyond.com/magic-items/adamantine-armor',
        rarity: Rarity.UNCOMMON,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Animated Shield',
        link: 'https://www.dndbeyond.com/magic-items/animated-shield',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Belt of Fire Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/belt-of-fire-giant-strength',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Belt of Frost Giant Strength (or stone)',
        link: 'https://www.dndbeyond.com/magic-items/belt-of-frost-giant-strength',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +1 (breastplate)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('breastplate'),
    },
    {
      weight: 1,
      value: {
        name: 'Candle of Invocation',
        link: 'https://www.dndbeyond.com/magic-items/candle-of-invocation',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +2 (chain mail)',
        link: 'https://www.dndbeyond.com/magic-items/armor-2',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +2 (chain shirt)',
        link: 'https://www.dndbeyond.com/magic-items/armor-2',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Cloak of Arachnida',
        link: 'https://www.dndbeyond.com/magic-items/cloak-of-arachnida',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Dancing Sword',
        link: 'https://www.dndbeyond.com/magic-items/dancing-sword',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Demon Armor',
        link: 'https://www.dndbeyond.com/magic-items/demon-armor',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Dragon Scale Mail',
        link: 'https://www.dndbeyond.com/magic-items/dragon-scale-mail',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Dwarven Plate',
        link: 'https://www.dndbeyond.com/magic-items/dwarven-plate',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Dwarven Thrower',
        link: 'https://www.dndbeyond.com/magic-items/dwarven-thrower',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Efreeti Bottle',
        link: 'https://www.dndbeyond.com/magic-items/efreeti-bottle',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Figurine of Wondrous Power (obsidian steed)',
        link: 'https://www.dndbeyond.com/magic-items/figurine-of-wondrous-power-obsidian-steed',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Frost Brand',
        link: 'https://www.dndbeyond.com/magic-items/frost-brand',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Helm of Brilliance',
        link: 'https://www.dndbeyond.com/magic-items/helm-of-brilliance',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Horn of Valhalla (bronze)',
        link: 'https://www.dndbeyond.com/magic-items/horn-of-valhalla-bronze',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Instrument of the Bards (Anstruth harp)',
        link: 'https://www.dndbeyond.com/magic-items/instrument-of-the-bards',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (absorbtion)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-absorption',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Iound stone (agility)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-agility',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (fortitude)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-fortitude',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (insight)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-insight',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (intellect)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-intellect',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (leadership)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-leadership',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (strength)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-strength',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +2 (leather)',
        link: 'https://www.dndbeyond.com/magic-items/armor-2',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Manual of Bodily Health',
        link: 'https://www.dndbeyond.com/magic-items/manual-of-bodily-health',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Manual of Gainful Exercise',
        link: 'https://www.dndbeyond.com/magic-items/manual-of-gainful-exercise',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: new WeightedTable([
        {
          weight: 5,
          value: {
            name: 'Manual of Clay Golems',
            link: 'https://www.dndbeyond.com/magic-items/manual-of-clay-golems',
            rarity: Rarity.VERY_RARE,
          },
        },
        {
          weight: 12,
          value: {
            name: 'Manual of Flesh Golems',
            link: 'https://www.dndbeyond.com/magic-items/manual-of-flesh-golems',
            rarity: Rarity.VERY_RARE,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Manual of Iron Golems',
            link: 'https://www.dndbeyond.com/magic-items/manual-of-iron-golems',
            rarity: Rarity.VERY_RARE,
          },
        },
        {
          weight: 2,
          value: {
            name: 'Manual of Stone Golems',
            link: 'https://www.dndbeyond.com/magic-items/manual-of-stone-golems',
            rarity: Rarity.VERY_RARE,
          },
        },

      ])
    },
    {
      weight: 1,
      value: {
        name: 'Manual of Quickness of Action',
        link: 'https://www.dndbeyond.com/magic-items/manual-of-quickness-of-action',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Mirror of Life Trapping',
        link: 'https://www.dndbeyond.com/magic-items/mirror-of-life-trapping',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Nine Lives Stealer',
        link: 'https://www.dndbeyond.com/magic-items/nine-lives-stealer',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Oathbow',
        link: 'https://www.dndbeyond.com/magic-items/oathbow',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +2 (scale mail)',
        link: 'https://www.dndbeyond.com/magic-items/armor-2',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Spellguard Shield',
        link: 'https://www.dndbeyond.com/magic-items/spellguard-shield',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +1 (splint)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('splint'),
    },
    {
      weight: 1,
      value: {
        name: 'Armor, +1 (studded leather)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('studded leather'),
    },
    {
      weight: 1,
      value: {
        name: 'Tome of Clear Thought',
        link: 'https://www.dndbeyond.com/magic-items/tome-of-clear-thought',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Tome of Leadership and Influence',
        link: 'https://www.dndbeyond.com/magic-items/tome-of-leadership-and-influence',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Tome of Understanding',
        link: 'https://www.dndbeyond.com/magic-items/tome-of-understanding',
        rarity: Rarity.VERY_RARE,
      },
    },
  ]);

  static I: WeightedTable<MagicItemEntry> = new WeightedTable<MagicItemEntry>([
    {
      weight: 5,
      value: {
        name: 'Defender',
        link: 'https://www.dndbeyond.com/magic-items/defender',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Hammer of Thunderbolts',
        link: 'https://www.dndbeyond.com/magic-items/hammer-of-thunderbolts',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Luck Blade',
        link: 'https://www.dndbeyond.com/magic-items/luck-blade',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 5,
      value: {
        name: 'Sword of Answering',
        link: 'https://www.dndbeyond.com/magic-items/sword-of-answering',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Holy Avenger',
        link: 'https://www.dndbeyond.com/magic-items/holy-avenger',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Ring of Djinni Summoning',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-djinni-summoning',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Ring of Invisibility',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-invisibility',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Ring of Spell Turning',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-spell-turning',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Rod of Lordly Might',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-lordly-might',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Staff of the Magi',
        link: 'https://www.dndbeyond.com/magic-items/staff-of-the-magi',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 3,
      value: {
        name: 'Vorpal Sword',
        link: 'https://www.dndbeyond.com/magic-items/vorpal-sword',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Belt of Cloud Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/belt-of-cloud-giant-strength',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +2 (breastplate)',
        link: 'https://www.dndbeyond.com/magic-items/armor-2',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +3 (chain mail)',
        link: 'https://www.dndbeyond.com/magic-items/armor-3',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +3 (chain shirt)',
        link: 'https://www.dndbeyond.com/magic-items/armor-3',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Cloak of Invisibility',
        link: 'https://www.dndbeyond.com/magic-items/cloak-of-invisibility',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: new WeightedTable([
        {
          weight: 1,
          value: {
            name: 'Crystal Ball of Mind Reading',
            link: 'https://www.dndbeyond.com/magic-items/crystal-ball-of-mind-reading',
            rarity: Rarity.LEGENDARY,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Crystal Ball of Telepathy',
            link: 'https://www.dndbeyond.com/magic-items/crystal-ball-of-telepathy',
            rarity: Rarity.LEGENDARY,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Crystal Ball of True Seeing',
            link: 'https://www.dndbeyond.com/magic-items/crystal-ball-of-true-seeing',
            rarity: Rarity.LEGENDARY,
          },
        },
      ]),
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +1 (half plate)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Iron Flask',
        link: 'https://www.dndbeyond.com/magic-items/iron-flask',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +3 (leather)',
        link: 'https://www.dndbeyond.com/magic-items/armor-3',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +1 (plate)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Robe of the Archmagi',
        link: 'https://www.dndbeyond.com/magic-items/robe-of-the-archmagi',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Rod of Resurrection',
        link: 'https://www.dndbeyond.com/magic-items/rod-of-resurrection',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +1 (scale mail)',
        link: 'https://www.dndbeyond.com/magic-items/armor-1',
        rarity: Rarity.RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Scarab of Protection',
        link: 'https://www.dndbeyond.com/magic-items/scarab-of-protection',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +2 (splint)',
        link: 'https://www.dndbeyond.com/magic-items/armor-2',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Armor, +2 (studded leather)',
        link: 'https://www.dndbeyond.com/magic-items/armor-2',
        rarity: Rarity.VERY_RARE,
      },
    },
    {
      weight: 2,
      value: {
        name: 'Well of Many Worlds',
        link: 'https://www.dndbeyond.com/magic-items/well-of-many-worlds',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: new WeightedTable([
        {
          weight: 2,
          value: {
            name: 'Armor, +2 (half plate)',
            link: 'https://www.dndbeyond.com/magic-items/armor-2',
            rarity: Rarity.VERY_RARE,
          },
        },
        {
          weight: 2,
          value: {
            name: 'Armor, +2 (plate)',
            link: 'https://www.dndbeyond.com/magic-items/armor-2',
            rarity: Rarity.VERY_RARE,
          },
        },
        {
          weight: 2,
          value: {
            name: 'Armor, +3 (studded leather)',
            link: 'https://www.dndbeyond.com/magic-items/armor-3',
            rarity: Rarity.LEGENDARY,
          },
        },
        {
          weight: 2,
          value: {
            name: 'Armor, +3 (breastplate)',
            link: 'https://www.dndbeyond.com/magic-items/armor-3',
            rarity: Rarity.LEGENDARY,
          },
        },
        {
          weight: 2,
          value: {
            name: 'Armor, +3 (splint)',
            link: 'https://www.dndbeyond.com/magic-items/armor-3',
            rarity: Rarity.LEGENDARY,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Armor, +3 (half plate)',
            link: 'https://www.dndbeyond.com/magic-items/armor-3',
            rarity: Rarity.LEGENDARY,
          },
        },
        {
          weight: 1,
          value: {
            name: 'Armor, +3 (plate)',
            link: 'https://www.dndbeyond.com/magic-items/armor-3',
            rarity: Rarity.LEGENDARY,
          },
        },
      ]),
    },
    {
      weight: 1,
      value: {
        name: 'Apparatus of Kwalish',
        link: 'https://www.dndbeyond.com/magic-items/apparatus-of-kwalish',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Armor of Invulnerability',
        link: 'https://www.dndbeyond.com/magic-items/armor-of-invulnerability',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Belt of Storm Giant Strength',
        link: 'https://www.dndbeyond.com/magic-items/belt-of-storm-giant-strength',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Cubic Gate',
        link: 'https://www.dndbeyond.com/magic-items/cubic-gate',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Deck of Many Things',
        link: 'https://www.dndbeyond.com/magic-items/deck-of-many-things',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Efreeti Chain',
        link: 'https://www.dndbeyond.com/magic-items/efreeti-chain',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('half plate'),
    },
    {
      weight: 1,
      value: {
        name: 'Horn of Valhalla (iron)',
        link: 'https://www.dndbeyond.com/magic-items/horn-of-valhalla-iron',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Instrument of the Bards (Ollamh harp)',
        link: 'https://www.dndbeyond.com/magic-items/instrument-of-the-bards',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (greater absorption)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-greater-absorption',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (mastery)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-mastery',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ioun Stone (regeneration)',
        link: 'https://www.dndbeyond.com/magic-items/ioun-stone-of-regeneration',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Plate Armor of Etherealness',
        link: 'https://www.dndbeyond.com/magic-items/plate-armor-of-etherealness',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: armorOfResistanceBuilder('plate'),
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Air Elemental Command',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-air-elemental-command',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Earth Elemental Command',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-earth-elemental-command',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Fire Elemental Command',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-fire-elemental-command',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Three Wishes',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-three-wishes',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Ring of Water Elemental Command',
        link: 'https://www.dndbeyond.com/magic-items/ring-of-water-elemental-command',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Sphere of Annihilation',
        link: 'https://www.dndbeyond.com/magic-items/sphere-of-annihilation',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Talisman of Pure Good',
        link: 'https://www.dndbeyond.com/magic-items/talisman-of-pure-good',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Talisman of the Sphere',
        link: 'https://www.dndbeyond.com/magic-items/talisman-of-the-sphere',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Talisman of Ultimate Evil',
        link: 'https://www.dndbeyond.com/magic-items/talisman-of-ultimate-evil',
        rarity: Rarity.LEGENDARY,
      },
    },
    {
      weight: 1,
      value: {
        name: 'Tome of the Stilled Tongue',
        link: 'https://www.dndbeyond.com/magic-items/tome-of-the-stilled-tongue',
        rarity: Rarity.LEGENDARY,
      },
    },
  ]);
}
