import WeightedTable from 'src/helpers/weighted-table';
import MagicItemTables from 'src/resources/magic-item-tables';
import {
  coinGenerator,
  Hoard,
  hoardGenerator,
  hoardItemGenerator,
  multiplyRoll,
} from 'src/helpers/treasure-generators';
import { DieExp } from 'src/helpers/dice';
import Gemstones from 'src/resources/gemstones';
import ArtObjects from 'src/resources/art-objects';

const CR_4_COINS = coinGenerator({
  copper: multiplyRoll(DieExp.fromString('6d6'), 100),
  silver: multiplyRoll(DieExp.fromString('3d6'), 100),
  gold: multiplyRoll(DieExp.fromString('2d6'), 10),
});

const CR_10_COINS = coinGenerator({
  copper: multiplyRoll(DieExp.fromString('2d6'), 100),
  silver: multiplyRoll(DieExp.fromString('2d6'), 1000),
  gold: multiplyRoll(DieExp.fromString('6d6'), 100),
  platinum: multiplyRoll(DieExp.fromString('3d6'), 10),
});

const CR_16_COINS = coinGenerator({
  gold: multiplyRoll(DieExp.fromString('4d6'), 100),
  platinum: multiplyRoll(DieExp.fromString('5d6'), 100),
});

const CR_17_COINS = coinGenerator({
  gold: multiplyRoll(DieExp.fromString('12d6'), 1000),
  platinum: multiplyRoll(DieExp.fromString('8d6'), 1000),
});

export default class TreasureHoards {
  static CR_4: WeightedTable<Hoard> = new WeightedTable([
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_4_COINS,
      }),
    },
    {
      weight: 10,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_10, 10),
      }),
    },
    {
      weight: 10,
      value: hoardGenerator({
        coins: CR_4_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
      }),
    },
    {
      weight: 10,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_50, 50),
      }),
    },
    {
      weight: 8,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_10, 10),
        magicItems: () => MagicItemTables.A.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 8,
      value: hoardGenerator({
        coins: CR_4_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.A.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 8,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.A.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_10, 10),
        magicItems: () => MagicItemTables.B.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_4_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.B.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d4'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.B.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_10, 10),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_4_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 7,
      value: hoardGenerator({
        coins: CR_4_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.F.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.F.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_4_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.G.getMultipleValues(1),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_4_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('2d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.G.getMultipleValues(1),
      }),
    },
  ]);

  static CR_10: WeightedTable<Hoard> = new WeightedTable([
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_10_COINS,
      }),
    },
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
      }),
    },
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_50, 50),
      }),
    },
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
      }),
    },
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.A.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.A.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
        magicItems: () => MagicItemTables.A.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.A.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.B.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.B.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
        magicItems: () => MagicItemTables.B.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight:4,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.B.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.D.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.D.getMultipleValues(1),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
        magicItems: () => MagicItemTables.D.getMultipleValues(1),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.D.getMultipleValues(1),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_25, 25),
        magicItems: () => MagicItemTables.F.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_50, 50),
        magicItems: () => MagicItemTables.F.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
        magicItems: () => MagicItemTables.F.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.F.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
        magicItems: () => MagicItemTables.G.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.G.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_10_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_100, 100),
        magicItems: () => MagicItemTables.H.getMultipleValues(1),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_10_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.H.getMultipleValues(1),
      }),
    },
  ]);

  static CR_16: WeightedTable<Hoard> = new WeightedTable([
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => [
          ...MagicItemTables.A.getDiceRoll(DieExp.fromString('1d4')),
          ...MagicItemTables.B.getDiceRoll(DieExp.fromString('1d6')),
        ],
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
        magicItems: () => [
          ...MagicItemTables.A.getDiceRoll(DieExp.fromString('1d4')),
          ...MagicItemTables.B.getDiceRoll(DieExp.fromString('1d6')),
        ],
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
        magicItems: () => [
          ...MagicItemTables.A.getDiceRoll(DieExp.fromString('1d4')),
          ...MagicItemTables.B.getDiceRoll(DieExp.fromString('1d6')),
        ],
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => [
          ...MagicItemTables.A.getDiceRoll(DieExp.fromString('1d4')),
          ...MagicItemTables.B.getDiceRoll(DieExp.fromString('1d6')),
        ],
      }),
    },
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 4,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.E.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
        magicItems: () => MagicItemTables.E.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
        magicItems: () => MagicItemTables.E.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.E.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => [
          ...MagicItemTables.F.getMultipleValues(1),
          ...MagicItemTables.G.getDiceRoll(DieExp.fromString('1d4')),
        ],
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
        magicItems: () => [
          ...MagicItemTables.F.getMultipleValues(1),
          ...MagicItemTables.G.getDiceRoll(DieExp.fromString('1d4')),
        ],
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
        magicItems: () => [
          ...MagicItemTables.F.getMultipleValues(1),
          ...MagicItemTables.G.getDiceRoll(DieExp.fromString('1d4')),
        ],
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => [
          ...MagicItemTables.F.getMultipleValues(1),
          ...MagicItemTables.G.getDiceRoll(DieExp.fromString('1d4')),
        ],
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_250, 250),
        magicItems: () => MagicItemTables.I.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('2d4'), ArtObjects.GP_750, 750),
        magicItems: () => MagicItemTables.I.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_500, 500),
        magicItems: () => MagicItemTables.I.getMultipleValues(1),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_16_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.I.getMultipleValues(1),
      }),
    },
  ]);

  static CR_17: WeightedTable<Hoard> = new WeightedTable([
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_17_COINS,
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d8')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d10'), ArtObjects.GP_2500, 2500),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d8')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d4'), ArtObjects.GP_7500, 7500),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d8')),
      }),
    },
    {
      weight: 3,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('1d8'), Gemstones.GP_5000, 5000),
        magicItems: () => MagicItemTables.C.getDiceRoll(DieExp.fromString('1d8')),
      }),
    },
    {
      weight: 8,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 8,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d10'), ArtObjects.GP_2500, 2500),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 8,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d4'), ArtObjects.GP_7500, 7500),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 8,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('1d8'), Gemstones.GP_5000, 5000),
        magicItems: () => MagicItemTables.D.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.E.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 6,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d10'), ArtObjects.GP_2500, 2500),
        magicItems: () => MagicItemTables.E.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d4'), ArtObjects.GP_7500, 7500),
        magicItems: () => MagicItemTables.E.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('1d8'), Gemstones.GP_5000, 5000),
        magicItems: () => MagicItemTables.E.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.G.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d10'), ArtObjects.GP_2500, 2500),
        magicItems: () => MagicItemTables.G.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d4'), ArtObjects.GP_7500, 7500),
        magicItems: () => MagicItemTables.G.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 1,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('1d8'), Gemstones.GP_5000, 5000),
        magicItems: () => MagicItemTables.G.getDiceRoll(DieExp.fromString('1d6')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d10'), ArtObjects.GP_2500, 2500),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d4'), ArtObjects.GP_7500, 7500),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 2,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('1d8'), Gemstones.GP_5000, 5000),
        magicItems: () => MagicItemTables.H.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('3d6'), Gemstones.GP_1000, 1000),
        magicItems: () => MagicItemTables.I.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d10'), ArtObjects.GP_2500, 2500),
        magicItems: () => MagicItemTables.I.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_17_COINS,
        artObjects: hoardItemGenerator(DieExp.fromString('1d4'), ArtObjects.GP_7500, 7500),
        magicItems: () => MagicItemTables.I.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
    {
      weight: 5,
      value: hoardGenerator({
        coins: CR_17_COINS,
        gemstones: hoardItemGenerator(DieExp.fromString('1d8'), Gemstones.GP_5000, 5000),
        magicItems: () => MagicItemTables.I.getDiceRoll(DieExp.fromString('1d4')),
      }),
    },
  ]);
}
