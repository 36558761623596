import WeightedTable from 'src/helpers/weighted-table';
import { MagicItemEntry, Rarity } from 'src/resources/magic-item-tables';

const RESISTANCE_TYPES: string[] = [
  'acid',
  'cold',
  'fire',
  'force',
  'lightning',
  'necrotic',
  'poison',
  'pyshcic',
  'radiant',
  'thunder',
];

export const armorOfResistanceBuilder = (armorType: string): WeightedTable<MagicItemEntry> => {
  return new WeightedTable<MagicItemEntry>(
    RESISTANCE_TYPES.map(type => {
      return {
        weight: 1,
        value: {
          name: `Armor of Resistance (${armorType}) (${type})`,
          link: 'https://www.dndbeyond.com/magic-items/armor-of-resistance',
          rarity: Rarity.RARE,
        },
      };
    })
  );
};

export const ringOfResistanceBuilder = (): WeightedTable<MagicItemEntry> => {
  return new WeightedTable<MagicItemEntry>(
    RESISTANCE_TYPES.map(type => {
      return {
        weight: 1,
        value: {
          name: `Ring of Resistance (${type})`,
          link: 'https://www.dndbeyond.com/magic-items/ring-of-resistance',
          rarity: Rarity.RARE,
        },
      };
    })
  );
};
