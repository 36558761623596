export class DieExp {
  readonly die: number;
  readonly num: number;
  readonly negative: boolean;

  static fromString(expression: string): DieExp {
    const neg = expression.startsWith('-');
    let signLess = expression;
    if (neg) {
      signLess = signLess.slice(1);
    }
    const [num, die] = signLess.split('d');
    return new this(parseInt(num), parseInt(die), neg);
  }

  constructor(num: number, die: number, negative = false) {
    this.die = die;
    this.num = num;
    this.negative = negative;
    this.min = this.min.bind(this);
    this.max = this.max.bind(this);
    this.avg = this.avg.bind(this);
    this.roll = this.roll.bind(this);

  }

  private sign(): number {
    return this.negative ? -1 : 1;
  }

  min(): number {
    return this.num * this.sign();
  }

  max(): number {
    return this.num * this.die * this.sign();
  }

  avg(): number {
    return this.num * (this.die / 2 + 0.5) * this.sign();
  }

  roll(): number {
    let result = 0;
    for (let i = 0; i < this.num; i++) {
      result += Math.ceil(Math.random() * this.die);
    }
    return result * this.sign();
  }
}

export class DiceRoll {
  readonly dice: DieExp[];

  constructor(...exps: DieExp[]) {
    this.dice = exps;
  }

  min(): number {
    return this.dice.reduce((prev: number, curr: DieExp) => prev + curr.min(), 0);
  }

  max(): number {
    return this.dice.reduce((prev: number, curr: DieExp) => prev + curr.max(), 0);
  }

  avg(): number {
    return this.dice.reduce((prev: number, curr: DieExp) => prev + curr.avg(), 0);
  }

  roll(): number {
    return this.dice.reduce((prev: number, curr: DieExp) => prev + curr.roll(), 0);
  }
}
