import { DiceRoll, DieExp } from 'src/helpers/dice';

export interface WeightedTableEntry<T> {
  value: T | WeightedTable<T> | ((payload?: unknown) => T);
  weight: number;
}

export default class WeightedTable<T> {
  readonly entries: WeightedTableEntry<T>[];
  readonly totalProbability: number;

  constructor(contents: WeightedTableEntry<T>[]) {
    this.totalProbability = contents.reduce((prev, curr) => prev + curr.weight, 0);
    this.entries = contents;
  }

  getValue(payload?: unknown): T {
    let index = Math.floor(Math.random() * this.totalProbability);
    for (const entry of this.entries) {
      if (index < entry.weight) {
        if (entry.value instanceof WeightedTable) {
          return entry.value.getValue();
        } else if (entry.value instanceof Function) {
          return entry.value(payload);
        } else {
          return entry.value;
        }
      } else {
        index -= entry.weight;
      }
    }

    throw new Error('Probabilities did not add up correctly.');
  }

  getMultipleValues(count: number): T[] {
    const results: T[] = [];
    for (let i = 0; i < count; i++) {
      results.push(this.getValue());
    }
    return results;
  }

  getDiceRoll(dice: DiceRoll | DieExp): T[] {
    return this.getMultipleValues(dice.roll());
  }
}
