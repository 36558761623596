import { Coins } from 'src/components/dnd/dnd-types';
import { DiceRoll, DieExp } from 'src/helpers/dice';
import { rndArray } from 'src/helpers/helpers';
import { MagicItemEntry } from 'src/resources/magic-item-tables';

export interface HoardItem {
  count: number;
  value: number;
  name: string;
}

export interface Hoard {
  coins: Coins;
  gemstones?: HoardItem[];
  artObjects?: HoardItem[];
  magicItems?: MagicItemEntry[];
}

export function coinGenerator(generators: {
  copper?: () => number;
  silver?: () => number;
  electrum?: () => number;
  gold?: () => number;
  platinum?: () => number;
}): () => Coins {
  return () => {
    return {
      copper: generators.copper ? generators.copper() : undefined,
      silver: generators.silver ? generators.silver() : undefined,
      electrum: generators.electrum ? generators.electrum() : undefined,
      gold: generators.gold ? generators.gold() : undefined,
      platinum: generators.platinum ? generators.platinum() : undefined,
    };
  };
}

export function multiplyRoll(dice: DiceRoll | DieExp, factor: number): () => number {
  return () => dice.roll() * factor;
}

export function hoardGenerator(generator: {
  coins: () => Coins;
  gemstones?: (homogenousTreasure: boolean) => HoardItem[];
  artObjects?: (homogenousTreasure: boolean) => HoardItem[];
  magicItems?: () => MagicItemEntry[];
}): (homegenousTreasure: boolean) => Hoard {
  return (homogenousTreasure: boolean): Hoard => {
    return {
      coins: generator.coins(),
      gemstones: generator.gemstones ? generator.gemstones(homogenousTreasure) : undefined,
      artObjects: generator.artObjects ? generator.artObjects(homogenousTreasure) : undefined,
      magicItems: generator.magicItems ? generator.magicItems() : undefined,
    };
  };
}

export function hoardItemGenerator(
  numberGen: DieExp | DiceRoll,
  table: string[],
  value: number
): (homegenous: boolean) => HoardItem[] {
  return (homogenous: boolean): HoardItem[] => {
    if (homogenous) {
      return [
        {
          count: numberGen.roll(),
          name: rndArray(table),
          value: value
        },
      ];
    } else {
      const count = numberGen.roll();
      const result: HoardItem[] = [];
      for (let i = 0; i < count; i++) {
        const item = rndArray(table);

        const existingItem = result.find(treasure => treasure.name === item);
        if (existingItem) {
          existingItem.count += 1;
        } else {
          result.push({ count: 1, name: item, value: value});
        }
      }
      return result;
    }
  };
}
