import React, { useState } from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import SEO from 'src/components/SEO';
import { H1, Paragraph, H2, A } from 'src/DesignSystem';
import ButtonGroup from 'src/components/ButtonGroup';
import IndividualTreasure from 'src/resources/individual-treasure-tables';
import WeightedTable from 'src/helpers/weighted-table';
import { Coins } from 'src/components/dnd/dnd-types';
import MagicItemTables, { MagicItemEntry } from 'src/resources/magic-item-tables';
import MagicItemField from 'src/components/dnd/MagicItemField';
import TreasureHoards from 'src/resources/treasure-hoard-tables';
import { Hoard } from 'src/helpers/treasure-generators';
import HoardItemField, { HoardItemType } from 'src/components/dnd/HoardItemField';
import CoinDisplay from 'src/components/dnd/CoinDisplay';

const PAGE_DESCRPTION = `Treasure and treasure hoard generators from the "Dungeon Master's Guide."`;

export default function Treasure(): JSX.Element {
  const [individualTreasure, setIndividualTreasure] = useState<Coins>();
  const [tableRoll, setTableRoll] = useState<MagicItemEntry>();
  const [hoardRoll, setHoardRoll] = useState<Hoard>();
  const [homegenousTreasure, setHomegenousTreasure] = useState<boolean>(false);

  return (
    <BasicLayout>
      <SEO title="Treasure Generator" description={PAGE_DESCRPTION} />
      <div className="max-w-4xl p-4 mx-auto text-gray-900 mb-8">
        <H1>Treasure Generators</H1>
        <Paragraph>
          Generate treasure using the random tables in{' '}
          <A
            href="https://www.dndbeyond.com/sources/dmg/treasure#Chapter7Treasure"
            target="blank"
            rel="noopener noreferrer"
          >
            Chapter 7
          </A>{' '}
          of the `&quot;Dungeon Master&apos;s Guide.`&quot;
        </Paragraph>
        <H2>Individual Treasure</H2>
        <Paragraph>Roll individual treasure for a monster, based on the monster&apos;s CR.</Paragraph>
        <ButtonGroup
          buttons={[
            {
              onClick: () =>
                setIndividualTreasure(new WeightedTable<Coins>(IndividualTreasure.CR4).getValue()),
              name: 'Challenge 0-4',
            },
            {
              onClick: () =>
                setIndividualTreasure(new WeightedTable<Coins>(IndividualTreasure.CR10).getValue()),
              name: 'Challenge 5-10',
            },
            {
              onClick: () =>
                setIndividualTreasure(new WeightedTable<Coins>(IndividualTreasure.CR16).getValue()),
              name: 'Challenge 11-16',
            },
            {
              onClick: () =>
                setIndividualTreasure(new WeightedTable<Coins>(IndividualTreasure.CR17).getValue()),
              name: 'Challenge 17+',
            },
          ]}
        />
        <CoinDisplay coins={individualTreasure} />
        <H2>Magic Item Tables</H2>
        <Paragraph>
          Roll on a specific table from the Dungeon Master&apos;s Guide random magic item tables. Tables
          A-E contain `&quot;minor magic items`&quot;, and F-I contain `&quot;major magic items.`&quot;
        </Paragraph>
        <ButtonGroup
          buttons={[
            {
              onClick: () => setTableRoll(MagicItemTables.A.getValue()),
              name: 'A',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.B.getValue()),
              name: 'B',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.C.getValue()),
              name: 'C',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.D.getValue()),
              name: 'D',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.E.getValue()),
              name: 'E',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.F.getValue()),
              name: 'F',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.G.getValue()),
              name: 'G',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.H.getValue()),
              name: 'H',
            },
            {
              onClick: () => setTableRoll(MagicItemTables.I.getValue()),
              name: 'I',
            },
          ]}
        />
        {tableRoll ? (
          <MagicItemField magicItem={tableRoll} />
        ) : (
          <div className="mt-4 h-12 rounded flex-grow bg-gray-300" />
        )}
        <H2>Treasure Hoard</H2>
        <Paragraph>Roll a treasure hoard!</Paragraph>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={homegenousTreasure}
              onChange={() => setHomegenousTreasure(!homegenousTreasure)}
            />
            <span className="ml-2">Use homogenous gemstones & art objects</span>
          </label>
        </div>
        <ButtonGroup
          buttons={[
            {
              onClick: () => setHoardRoll(TreasureHoards.CR_4.getValue(homegenousTreasure)),
              name: 'Challenge 0-4',
            },
            {
              onClick: () => setHoardRoll(TreasureHoards.CR_10.getValue(homegenousTreasure)),
              name: 'Challenge 5-10',
            },
            {
              onClick: () => setHoardRoll(TreasureHoards.CR_16.getValue(homegenousTreasure)),
              name: 'Challenge 11-16',
            },
            {
              onClick: () => setHoardRoll(TreasureHoards.CR_17.getValue(homegenousTreasure)),
              name: 'Challenge 17+',
            },
          ]}
        />

        {hoardRoll?.coins && <CoinDisplay coins={hoardRoll.coins} />}
        {hoardRoll?.gemstones?.map((item, index) => (
          <HoardItemField key={index} hoardItem={item} type={HoardItemType.GEMSTONE} />
        ))}
        {hoardRoll?.artObjects?.map((item, index) => (
          <HoardItemField key={index} hoardItem={item} type={HoardItemType.ART_OBJECT} />
        ))}
        {hoardRoll?.magicItems?.map((item, index) => (
          <MagicItemField key={index} magicItem={item} />
        ))}
      </div>
    </BasicLayout>
  );
}
